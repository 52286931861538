<template>
    <div id="Report Preview">
        <v-container fluid app>
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
                no-click-animation
            >
                <v-card v-if="dialog">
                    <v-toolbar
                    dark
                    flat
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    >
                        <v-row no-gutters class="d-flex align-center justify-start mt-1">
                            <v-col class="d-flex justify-start">
                                <div class="d-flex align-start mr-12">
                                    <!-- <v-icon x-large color="white" style="margin-top: 6px;">
                                        mdi-chart-line
                                    </v-icon> -->
                                    <v-img
                                        max-width="120"
                                        max-height="56"
                                        src="img\znap-home.png"
                                    ></v-img>
                                </div>
                                <div class="d-flex flex-column align-start">
                                    <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                                    <div class="mb-2 d-flex align-center">
                                        <v-icon small color="white" class="mr-2">mdi-information-outline</v-icon>
                                        <span class="text-caption">{{ tableDescription }}</span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-spacer />

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                    v-on="{ ...tooltip}"
                                    min-width="48px"
                                    @click="getData()"
                                    :disabled="select.length < 1"
                                    depressed
                                >
                                    <v-icon class="white--text">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Atualizar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                    v-on="on"
                                    min-width="48px"
                                    @click="exportToExcel()"
                                    :loading="loadingExport"
                                    depressed
                                >
                                    <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Exportar Excel</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn v-if="slides.length > 0" color="transparent" class="mb-2 mr-4 mt-2"
                                    v-on="{ ...tooltip}"
                                    min-width="48px"
                                    @click.native="drawer = true"
                                    depressed
                                >
                                    <v-icon class="white--text">mdi-comment-text-multiple</v-icon>
                                </v-btn>
                            </template>
                            <span>Comentários</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                    v-on="{ ...tooltip}"
                                    min-width="48px"
                                    @click="showControllers = !showControllers"
                                    depressed
                                >
                                    <v-icon v-if="showControllers" class="white--text">mdi-eye-off</v-icon>
                                    <v-icon v-if="!showControllers" class="white--text">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="showControllers">Esconder controles de slide</span>
                            <span v-if="!showControllers">Mostrar controles de slide</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="transparent" class="mb-2 mt-2"
                                    v-on="{ ...tooltip}"
                                    min-width="48px"
                                    @click="$root.$emit('closeDialog')"
                                    depressed
                                >
                                    <v-icon class="white--text">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </v-toolbar>

                    <v-container fluid fill-height>
                        <v-layout v-if="showControllers">
                            <v-flex xs4>
                                <v-select
                                    v-model="select"
                                    dense
                                    :items="eventItems"
                                    class="ml-2 mt-5 mb-4"
                                    item-text="description"
                                    item-value="id"                
                                    label="Eventos"
                                    multiple
                                    hint="Selecione os Eventos para comparar"
                                    persistent-hint
                                ></v-select>
                                <!-- <v-layout v-if="operation != 'Nenhuma'" wrap>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectFirst"
                                            :items="items"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="text"
                                            item-value="id"                
                                            label="Primeiro evento"
                                            hint="Selecione um primeiro evento"
                                            persistent-hint
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectSecond"
                                            :items="items"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="text"
                                            item-value="id"                
                                            label="Segundo evento"
                                            hint="Selecione um segundo evento para comparar"
                                            persistent-hint
                                        ></v-select>
                                    </v-flex>
                                </v-layout> -->
                            </v-flex>
                            <v-flex xs3 v-if="slides.length > 1">
                                <v-select
                                    v-model="slideTime"
                                    :items="time"
                                    class="mx-4 my-4 comboBox"
                                    item-text="text"
                                    item-value="time"                
                                    label="Tempo de troca do Slide"
                                    hint="Selecione o tempo em segundos"
                                    persistent-hint
                                ></v-select>
                            </v-flex>
                            <v-flex xs3 v-if="slides.length > 1">
                                <v-select
                                    v-model="selectSlide"
                                    :items="slides"
                                    class="mx-2 my-4 comboBox"
                                    item-text="slide_title"
                                    item-value="id_dynamic_report_slide"               
                                    label="Slide"
                                    hint="Selecione um dos slides"
                                    @input="setPage"
                                    return-object
                                    persistent-hint
                                ></v-select>
                            </v-flex>
                            <v-flex xs2 v-if="slides.length > 1 && !loadingEvents">
                                <v-btn :disabled="page === 0" class=" ml-4 mt-6" icon color="primary" @click="page = 0">
                                    <v-icon class="mr-2">mdi-chevron-double-left</v-icon>
                                </v-btn>
                                <v-btn :disabled="page === 0" class="mt-6" icon color="primary" @click="page--">
                                    <v-icon class="mr-2">mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn :disabled="page === slides.length-1" class="mt-6" icon color="primary" @click="page++">
                                    <v-icon class="mr-2">mdi-chevron-right</v-icon>
                                </v-btn>
                                <v-btn :disabled="page === slides.length-1" class="mt-6" icon color="primary" @click="page = slides.length-1">
                                    <v-icon class="mr-2">mdi-chevron-double-right</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <!-- <v-btn class="my-4" elevation="2" @click="getData()">Refresh</v-btn> -->                      
                        <v-carousel
                            v-if="!loadingEvents && slides.length > 0"
                            v-model="page"
                            :cycle="slideTime !== null"
                            :interval="slideTime"
                            height="auto"
                            :show-arrows="false"
                            hide-delimiters
                        >
                            <v-carousel-item
                                v-for="slide in slides"
                                :key="slide.id_dynamic_report_slide"
                            >
                                <vue-pivottable-preview
                                    v-if="!loadingEvents"
                                    v-model="config"
                                    :data="data"
                                    :rowTotal="showTotalRow"
                                    :colTotal="showTotalCol"
                                    :localeStrings="localeStrings[locale]"
                                    :rendererName="slide.pivot_params_json.renderer"
                                    :aggregatorName="slide.pivot_params_json.aggregator"
                                    :tableColorScaleGenerator="colorScaleGenerator"
                                    :attributes="attributes"
                                    :sorters="sorters"
                                    :rows="slide.pivot_params_json.rows"
                                    :cols="slide.pivot_params_json.cols"
                                    :vals="slide.pivot_params_json.vals"
                                    :disabledFromDragDrop="disabledFromDragDrop"
                                    :sortonlyFromDragDrop="sortonlyFromDragDrop"
                                    :hiddenFromDragDrop="hiddenFromDragDrop"
                                    rowOrder="key_a_to_z"
                                    class="mt-n12"
                                ></vue-pivottable-preview>
                            </v-carousel-item>
                        </v-carousel>

                        <vue-pivottable-preview
                            v-if="!loadingEvents && slides.length < 1"
                            v-model="config"
                            :data="data"
                            :rowTotal="showTotalRow"
                            :colTotal="showTotalCol"
                            :localeStrings="localeStrings[locale]"
                            :rendererName="rendererName"
                            :aggregatorName="aggregatorName"
                            :tableColorScaleGenerator="colorScaleGenerator"
                            :attributes="attributes"
                            :sorters="sorters"
                            :rows="rows"
                            :cols="cols"
                            :vals="vals"
                            :disabledFromDragDrop="disabledFromDragDrop"
                            :sortonlyFromDragDrop="sortonlyFromDragDrop"
                            :hiddenFromDragDrop="hiddenFromDragDrop"
                            rowOrder="key_a_to_z"
                            class="mt-n12"
                        ></vue-pivottable-preview>

                        <v-container fluid fill-height transition="fade-transition">
                            <v-layout align-center justify-center>
                                <span class="h1" v-if="data.length < 1 && !loadingEvents">
                                    Selecione um ou mais eventos para gerar a tabela
                                </span>
                                <v-progress-circular
                                    v-if="loadingEvents"
                                    width="10"
                                    size="100"
                                    color="primary"
                                    indeterminate
                                    >
                                </v-progress-circular>
                            </v-layout>
                        </v-container>
                    </v-container>
                    <comments
                        v-if="show && slides.length > 0" 
                        :drawer="drawer"
                        @closeDrawer="drawer=false"
                        :parentPayload="{selectedSlide: slides[page].id_dynamic_report_slide, id_event: slides[page].pivot_params_json.events}"
                    ></comments>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
// import { VuePivottableUi, VuePivottable, PivotUtilities, Renderer } from "vue-pivottable"
import { VuePivottablePreview, PivotUtilities, Renderer } from "../../../lib/vue-pivottable/src"
import { scaleLinear } from "d3-scale"
import Comments from '../../components/znap/CommentsMenuReport'

export default {
    name: "ReportPreview",

    components: { VuePivottablePreview, Comments },

    props: {
      dialog : {
        type: Boolean,
        required: true
      },
      optionPreview: {
          type: String,
          required: true
      },
      selectedYear: {
          type: String,
          required: true
      },
      colsFields: {
          type: Array,
          required: true
      },
      rowsFields: {
          type: Array,
          required: true
      },
      renderer: {
          type: String,
          required: true
      },
      aggregator: {
          type: String,
          required: true
      },
      vals: {
          type: Array,
          required: true
      },
      headers: {
          type: Array,
          required: true
      },
      filterColsList: {
          type: Array,
          required: true
      },
      events: {
          type: Array,
          required: true
      },
      slidesList : {
        type: Array,
        required: true
      },
      showTotal : {
        type: Object,
        required: true
      }
    },

    computed: {
        endpoint() {
            return [this.$ipPurchasePlanning, "purchase-planning"]
        },

        endpointDynamicReport() {
            return [this.$ipDynamicReport, "dynamic-report"]
        },

        endpointSaveReport() {
            return [this.$ipDynamicReport, "dynamic-report-slide"]
        },

        endpointUser() {
            return [this.$ipUser, "user"]
        },

        unusedAttrs() {
            return this.config.unusedAttrs
        },

        aggregators() {
            const usFmt = PivotUtilities.numberFormat()

            return ((tpl) => ({
                Sum: tpl.sum(usFmt),
            }))(PivotUtilities.aggregatorTemplates)
        },

        sorters () {
            // const sortAs = PivotUtilities.sortAs(this.accountGroups)
            let orderedArray = [...this.accountGroups]
            return {
                "Conta gerencial": PivotUtilities.sortAs([...orderedArray]),
                "Ano/mês": PivotUtilities.sortAs([...this.yearMonths]),
                "Período (mês)": PivotUtilities.sortAs([...this.periodMonths])
            }
        },

        renderers() {
            const TableRenderer = Renderer.TableRenderer
            return (() => ({
                Table: TableRenderer.Table,
                "Table Heatmap": TableRenderer["Table Heatmap"],
                "Table Col Heatmap": TableRenderer["Table Col Heatmap"],
                "Table Row Heatmap": TableRenderer["Table Row Heatmap"],
                "Export Table TSV": TableRenderer["Export Table TSV"],
            }))()
        },

        valUnused() {
            return document.getElementsByClassName("pvtUnused")[0].offsetHeight
        },
    },

    data: () => ({
        tableName: "Preview de Relatórios",
        tableIcon: "mdi-table-large",
        tableDescription: "Forneça uma descrição para este relatório.",
        tableRows: 100000,

        noDataMessage: "",
        noData: true,

        drawer: false,
        show: false,
        showControllers: true,

        select: [],
        selectFirst: null,
        selectSecond: null,
        items: [],
        eventItems: [],
        id_account_group_version: 0,

        columns: [],
        columnsBiYear: [],
        columnsYear: [],
        columnsYearMonth: [],

        loadingEvents: false,
        loadingVals: false,
        loadingExport: false,
        labelClass: [
            'position: absolute; margin-top: 375px;',
            true,
            false
        ],

        saveDialog: false,
        openDialog: false,
        copyDialog: false,
        newDialog: false,

        selectedReport: null,
        selectedSlide: null,

        selectSlide: null,

        zoom: false,

        option: "YEAR",
        operator: null,
        operation: "Nenhuma",
        operations: ["- (Diferença)", "% (Percentual)", "Nenhuma"],
        filterDialog: false,
        valueFilter: {},

        step: "1",
        page: "0",
        slideTime: null,
        time: [
            {
                text: "Desabilitado",
                time: null
            },
            {
                text: "5 segundos",
                time: "5000"
            },
            {
                text: "15 segundos",
                time: "15000"
            },
            {
                text: "30 segundos",
                time: "30000"
            },
            {
                text: "60 segundos",
                time: "60000"
            },
        ],

        report: {
            id: null,
            description: "",
            number: null,
            title: null,
            text: null,
            format: null,
            pivotTable: {
                option: null,
                operation: null,
                renderer: null,
                aggregator: null,
                vals: [],
                headers: [],
                cols: [],
                rows: [],
                filters: []
            }
        },

        slides: [],
        filterCols: [],

        slide: {},
        reportOpen: {},

        searchFilters: {},

        config: {},
        filteredData: [],
        showTotalRow: true,
        showTotalCol: true,
        data: [],
        dataRows: [],
        attributes: [],
        rows: ["Conta gerencial"],
        cols: ["Evento"],
        valsHeight: null,
        disabledFromDragDrop: [],
        hiddenFromDragDrop: [],
        sortonlyFromDragDrop: [],
        pivotColumns: [],
        loading: false,
        aggregatorName: "Sum",
        rendererName: "Table",
        dataSourceSettings: {  
            showGrandTotals: false,
            showSubTotals: false,
        },
        afterOpen: false,

        yearReference: '1',
        
        accountGroups: [],
        yearMonths: [
            'Jan/20', 'Fev/20', 'Mar/20', 'Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20', 'Set/20', 'Out/20', 'Nov/20', 'Dev/20',
            'Jan/21', 'Fev/21', 'Mar/21', 'Abr/21', 'Mai/21', 'Jun/21', 'Jul/21', 'Ago/21', 'Set/21', 'Out/21', 'Nov/21', 'Dev/21',
            'Jan/22', 'Fev/22', 'Mar/22', 'Abr/22', 'Mai/22', 'Jun/22', 'Jul/22', 'Ago/22', 'Set/22', 'Out/22', 'Nov/22', 'Dev/22',
            'Jan/23', 'Fev/23', 'Mar/23', 'Abr/23', 'Mai/23', 'Jun/23', 'Jul/23', 'Ago/23', 'Set/23', 'Out/23', 'Nov/23', 'Dev/23'
        ],
        periodMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],

        localeStrings: {
            en: {
                renderError:
                    "An error occurred rendering the PivotTable results.",
                computeError:
                    "An error occurred computing the PivotTable results.",
                uiRenderError: "An error occurred rendering the PivotTable UI.",
                selectAll: "Select All",
                selectNone: "Select None",
                tooMany: "too many values to show",
                filterResults: "Filter values",
                totals: "Totals",
                only: "only",
                rendererNames: {
                    Table: "Table",
                    "Table Heatmap": "Table Heatmap",
                    "Table Col Heatmap": "Table Col Heatmap",
                    "Table Row Heatmap": "Table Row Heatmap",
                    "Export Table TSV": "Export Table TSV",
                    "Grouped Column Chart": "Grouped Column Chart",
                    "Stacked Column Chart": "Stacked Column Chart",
                    "Grouped Bar Chart": "Grouped Bar Chart",
                    "Stacked Bar Chart": "Stacked Bar Chart",
                    "Line Chart": "Line Chart",
                    "Dot Chart": "Dot Chart",
                    "Area Chart": "Area Chart",
                    "Scatter Chart": "Scatter Chart",
                    "Multiple Pie Chart": "Multiple Pie Chart",
                },
                aggregatorMap: {
                    Count: "Count",
                    "Count Unique Values": "Count Unique Values",
                    "List Unique Values": "List Unique Values",
                    Sum: "Sum",
                    "Integer Sum": "Integer Sum",
                    Average: "Average",
                    Median: "Median",
                    "Sample Variance": "Sample Variance",
                    "Sample Standard Deviation": "Sample Standard Deviation",
                    Minimum: "Minimum",
                    Maximum: "Maximum",
                    First: "First",
                    Last: "Last",
                    "Sum over Sum": "Sum over Sum",
                    "Sum as Fraction of Total": "Sum as Fraction of Total",
                    "Sum as Fraction of Rows": "Sum as Fraction of Rows",
                    "Sum as Fraction of Columns": "Sum as Fraction of Columns",
                    "Count as Fraction of Total": "Count as Fraction of Total",
                    "Count as Fraction of Rows": "Count as Fraction of Rows",
                    "Count as Fraction of Columns":
                        "Count as Fraction of Columns",
                },
            },
            pt: {
                renderError:
                    "Ocorreu um erro ao renderizar os resultados da tabela dinâmica.",
                computeError:
                    "Ocorreu um erro ao calcular os resultados da tabela dinâmica.",
                uiRenderError: "Ocorreu um erro ao renderizar a Interface de Uso da tabela dinâmica.",
                selectAll: "Selecionar tudo",
                selectNone: "Selecione Nenhum",
                tooMany: "muitos valores para mostrar",
                filterResults: "Filtrar valores",
                totals: "Totais",
                only: "apenas",
                rendererMap: {
                    Table: 'Tabela',
                    'Table Heatmap': 'Mapa de calor da tabela',
                    'Table Col Heatmap': 'Mapa de calor de coluna da tabela',
                    'Table Row Heatmap': 'Mapa de calor de linha da tabela',
                    'Export Table TSV': 'Copiar para Excel',
                    'Grouped Column Chart': 'Gráfico de colunas agrupadas',
                    'Stacked Column Chart': 'Gráfico de colunas empilhadas',
                    'Grouped Bar Chart': 'Gráfico de Barras Agrupadas',
                    'Stacked Bar Chart': 'Gráfico de Barras Empilhadas',
                    'Line Chart': 'Gráfico de linhas',
                    'Dot Chart': 'Gráfico de pontos',
                    'Area Chart': 'Gráfico de área',
                    'Scatter Chart': 'Gráfico de dispersão',
                    'Multiple Pie Chart': 'Gráfico de pizza múltipla',
                },
                aggregatorMap: {
                    Count: "Contagem",
                    "Count Unique Values": "Contar valores exclusivos",
                    "List Unique Values": "Listar valores únicos",
                    Sum: "Soma",
                    "Integer Sum": "Soma Inteira",
                    Average: "Média",
                    Median: "Mediana",
                    "Sample Variance": "Variância da amostra",
                    "Sample Standard Deviation": "Desvio padrão da amostra",
                    Minimum: "Mínimo",
                    Maximum: "Máximo",
                    First: "Primeiro",
                    Last: "Último",
                    "Sum over Sum": "Soma sobre Soma",
                    "Sum as Fraction of Total": "Soma como fração do total",
                    "Sum as Fraction of Rows": "Soma como fração de linhas",
                    "Sum as Fraction of Columns": "Soma como fração de colunas",
                    "Count as Fraction of Total": "Contar como fração do total",
                    "Count as Fraction of Rows": "Contar como fração de linhas",
                    "Count as Fraction of Columns":
                        "Contar como fração de colunas",
                }
            },
                ko: {
                renderError: '피벗 테이블 결과를 렌더링하는 동안 오류가 발생 했습니다.',
                computeError: '피벗 테이블 결과를 계산하는 동안 오류가 발생 했습니다.',
                uiRenderError: '피벗 테이블 UI를 렌더링하는 동안 오류가 발생 했습니다.',
                selectAll: '모두 선택',
                selectNone: '선택 안함',
                tooMany: '표시 할 값이 너무 많습니다.',
                filterResults: '값 필터링',
                totals: '합계',
                only: '단독',
                rendererMap: {
                    Table: '테이블',
                    'Table Heatmap': '테이블 히트맵',
                    'Table Col Heatmap': '테이블 열 히트맵',
                    'Table Row Heatmap': '테이블 행 히트맵',
                    'Export Table TSV': '테이블 TSV로 내보내기',
                    'Grouped Column Chart': '그룹화된 차트',
                    'Stacked Column Chart': '누적 차트',
                    'Grouped Bar Chart': '그룹화된 막대형 차트',
                    'Stacked Bar Chart': '누적 막대형 차트',
                    'Line Chart': '라인 차트',
                    'Dot Chart': '도트 차트',
                    'Area Chart': '영역 차트',
                    'Scatter Chart': '분산형 차트',
                    'Multiple Pie Chart': '다중 원형 차트'
                },
                aggregatorMap: {
                    Count: '개수',
                    'Count Unique Values': '고유 값 개수',
                    'List Unique Values': '고유 값 목록',
                    Sum: '합계',
                    'Integer Sum': '정수 합계',
                    Average: '평균',
                    Median: '중앙',
                    'Sample Variance': '표본 분산',
                    'Sample Standard Deviation': '샘플 표준 편차',
                    Minimum: '최소',
                    Maximum: '최대',
                    First: '첫 번째',
                    Last: '마지막',
                    'Sum over Sum': '누적 합계',
                    'Sum as Fraction of Total': '부분별 비율 합계',
                    'Sum as Fraction of Rows': '행별 비율 합계',
                    'Sum as Fraction of Columns': '열별 비율 합계',
                    'Count as Fraction of Total': '전체 중 부분 개수',
                    'Count as Fraction of Rows': '행 부분 개수',
                    'Count as Fraction of Columns': '열 부분 개수'
                }
            }
        },
        locale: "pt",
    }),

    created () {
        this.getEvents()
        this.callHeaders()
        // this.select = this.slides[this.page].pivot_params_json.events
        // this.columns = this.slides[this.page].pivot_params_json.headers
        // this.getReports()
        // this.getData()
    },

    methods: {
        colorScaleGenerator(values) {
            const scale = scaleLinear()
                .domain([0, Math.max.apply(null, values)])
                .range(["#fff", "#88f"])
            return (x) => {
                return { "background-color": scale(x) }
            }
        },

        async callHeaders() {
            // await this.getHeaders('BI_YEAR')
            await this.getHeaders('YEAR')
            await this.getHeaders('YEAR_MONTH')
            this.columns = this.columnsBiYear
        },

        async getHeaders(option) {
            try {
                const payload = {
                    queryType: option,
                    showCommonHeaders: true
                }
                
                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse-headers",
                    payload
                )
                if (res) {
                    // if (option === 'BI_YEAR') {
                    //     this.columnsBiYear = res.data.headers
                    // }
                    if (option === 'YEAR') {
                        this.columnsYear = res.data.headers
                    }
                    if (option === 'YEAR_MONTH') {
                        this.columnsYearMonth = res.data.headers
                    }
                    // this.option = option
                    // this.columns = res.data.headers
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getData() {
            this.loadingEvents = true
            this.loadingVals = true

            let yearReference = parseInt(this.yearReference)

            const filterConditions = []
            let filters = []
            this.slides.length > 0 ? filters = this.slides[this.page].pivot_params_json.filterColumn : filters = this.filterCols
            if (filters) {
                for (let cont = 0; cont < filters.length; cont++) {
                    let filter = filters[cont]
                    if (filter.selectedFilters.length > 0) {
                        let values = []
                        
                        values = [...filter.selectedFilters]

                        filterConditions.push({
                            AndOr: "AND",
                            column: filter.columnId,
                            operator: "IN",
                            value: values
                        })
                    }
                }
            }
            if (this.selectFirst) {
                this.select = this.selectSecond != null ? this.select = [this.selectFirst, this.selectSecond]
                                                : this.select = [this.selectFirst]
            }

            let idAccountGroupVersion = this.items.find(i => i.id === this.select[0]).id_account_group_version
            this.eventItems = this.items.filter(i => i.id_account_group_version === idAccountGroupVersion)
            try {
                const payload = {
                    id_account_group_version: idAccountGroupVersion,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: this.operator,
                    ResultInArray: true,
                    queryType: this.option,
                    yearReference: yearReference,
                    filter: {
                        page: 0,
                        tableRows: 100000,
                        conditions: filterConditions,
                        having: [],
                    },
                }

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse",
                    payload
                )
                if (res) {
                    this.dataRows = res.data.rows
                    this.data = this.dataRows
                    this.pivotColumns = this.columns
                    this.disabledFromDragDrop = []
                    this.hiddenFromDragDrop = []
                    this.attributes = []

                    for (let cont = 0; cont < this.columns.length; cont++) {
                        let column = this.columns[cont]
                        if (column.order === undefined || column.visible === false) {
                            this.hiddenFromDragDrop.push(column.text)
                        } else {
                            this.attributes.push(column.text)
                        }
                        // if (!column.valueColumn) {
                        //     this.hiddenFromAggregators.push(column.text)
                        // }
                    }
                    this.loadingEvents = false
                }
            } catch (err) {
                this.loadingEvents = false
                this.loadingVals = false
                this.$fnError(err)
            }
        },

        async getEvents() {
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_module',
                                operator: '=',
                                value: 6
                            }
                        ]
                    }
                }
                let res = await this.$http.post(
                    this.$ipEvent + "event" + "/list-options",
                    payload
                )
                if (res) {
                    this.items = res.data.rows
                    // if(res.data.rows.length > 0) this.id_account_group_version = res.data.rows[0].id_account_group_version
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getReports () {
            try {
                const payload = {
                    filter: {
                        page: 0,
                        tableRows: 10000,
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_dynamic_report",
                                operator: ">=",
                                value: 1
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report' + "/list",
                    payload
                )
                if (res) {
                    this.reports = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getSlides (report) {
            try {
                const payload = {
                    filter: {
                        page: 0,
                        tableRows: 10000,
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_dynamic_report",
                                operator: "=",
                                value: report
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/list",
                    payload
                )
                if (res) {
                    this.slides = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setPage () {
            //let slideIndex = this.slides.findIndex(s => s.id_dynamic_report_slide === this.selectedSlide.id_dynamic_report_slide)
            //this.page = slideIndex
            if (this.slides.length > 0) {
                this.slides.forEach((slide, index) => {
                    if (this.selectSlide.id_dynamic_report_slide === slide.id_dynamic_report_slide) {
                        this.page = index
                    }
                })
            }
        },

         /* setSlide () {
            this.reports.forEach((item) => {
                item.id_dynamic_report === this.selectedReport ? this.reportOpen = item : false
            })
            this.slides.forEach((slide) => {
                if (typeof slide.pivot_params_json === "string") {
                    slide.pivot_params_json = JSON.parse(slide.pivot_params_json)
                }
                slide.id_dynamic_report_slide === this.selectedSlide ? this.slide = slide : false
            })
            this.report = {
                id: this.selectedReport,
                description: this.slide.dynamic_report,
                number: this.slide.slide_sequence,
                title: this.slide.slide_title,
                text: this.slide.slide_text,
                format: this.slide.slide_css,
                pivotTable: this.slide.pivot_params_json
            }

            this.selectSlide = this.slide.id_dynamic_report_slide

            this.tableName = this.slide.slide_title
            this.tableDescription = this.slide.dynamic_report
            this.select = this.slide.pivot_params_json.events
            this.columns =  this.slide.pivot_params_json.headers
            this.rendererName = this.slide.pivot_params_json.renderer
            this.aggregatorName = this.slide.pivot_params_json.aggregator
            this.cols = this.slide.pivot_params_json.cols
            this.rows = this.slide.pivot_params_json.rows
            this.option = this.slide.pivot_params_json.option
            this.operation =  this.slide.pivot_params_json.operation
            this.vals =  this.slide.pivot_params_json.vals

            this.getData()

        }, */

        async exportToExcel () {
            this.loadingExport = true
            try {
                const payload = {
                    id_account_group_version: this.id_account_group_version,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: false,
                    ResultInArray: true,
                    queryType: this.option
                }

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/report-analyse",
                    payload
                )
                if (res) {
                    this.loadingExport = false
                    window.open(res.data.token)
                }
            } catch (err) {
                this.loadingExport = false
                this.$fnError(err)
            }
        },

        async showFilterItems(filter) {
            if (filter?.items?.length < 1) {
                // this.loadingFilters.push(filter.name)

                try {
                    let res = await this.$http.post(filter.server + '/list-options', { filter: { conditions: filter.conditions } })
                    // let res = await this.$http.post(filter.local + '/list-options', {})
                    if (res) {
                        let foundFilter = this.filterCols.find(f => f.columnId === filter.columnId)
                        foundFilter.items = res.data.rows

                        // this.loadingFilters.pop()
                    }
                } catch (err) {
                    // this.loadingFilters.pop()
                    this.$fnError(err)
                }
            }
        }
    },

    watch: {
        select (val) {
            this.items.forEach((item) => {
                if (item.id === val[0]) {
                    this.id_account_group_version = item.id_account_group_version
                }
            })
            this.showFilterItems(this.filterCols.find(filter => filter.columnId == "id_account_group")).then(() => {
                let filter = this.filterCols.find(filter => filter.columnId == "id_account_group") || { items: [] }
                let filteredItems = filter.items.sort((a, b) => {
                    return (a.account_order > b.account_order) ? 1 : ((b.account_order > a.account_order) ? -1 : 0);
                })
                let accountTexts = []
                filteredItems.forEach((filter => {
                    accountTexts.push(filter.text)
                }))
                this.accountGroups = accountTexts
            })
            // this.getData()
        },
        config: {
            // eslint-disable-next-line no-unused-vars
            handler(value, oldValue) {
                const PivotData = PivotUtilities.PivotData
                this.data = this.dataRows
                this.filteredData = new PivotData(value).getFilteredData()
            },
            deep: true,
            immediate: false,
        },

        dialog () {
            this.slides = this.slidesList

            if (!this.dialog) {
                this.slides = []
                this.tableName = "Preview de Relatórios"
                this.tableDescription = "Forneça uma descrição para este relatório."
                this.show = false
                this.afterOpen = false
            } else {
                if (this.slides.length > 0){
                    this.page = '0'
                    this.filterCols = this.slides[this.page].pivot_params_json.filterColumn
                    this.select = this.slides[this.page].pivot_params_json.events
                    this.columns = this.slides[this.page].pivot_params_json.headers
                    this.option = this.slides[this.page].pivot_params_json.option
                    this.yearReference = this.slides[this.page].pivot_params_json.selectedYear
                    this.showTotalRow = this.slides[this.page].pivot_params_json.showTotalRow
                    this.showTotalCol = this.slides[this.page].pivot_params_json.showTotalCol
                    this.show = true
                    // this.getData()
                } else {
                    //MANDAR HEADERS, EVENTS E VALS COMO PROPS QUANDO NÃO TIVER RELATÓRIO SELECIONADO
                    this.filterCols = this.filterColsList
                    this.select = this.events
                    this.columns = this.headers
                    this.rendererName = this.renderer
                    this.aggregatorName = this.aggregator
                    this.cols = this.colsFields
                    this.rows = this.rowsFields
                    this.option = this.optionPreview
                    this.yearReference = this.selectedYear
                    this.showTotalRow = this.showTotal.row
                    this.showTotalCol = this.showTotal.col
                    //this.callHeaders()
                    // this.getData()
                }
                this.getData()
                this.afterOpen = true
            }

        },

        page (value) {
            this.slides.length > 0 ? this.selectSlide = this.slides[value].id_dynamic_report_slide : false

            this.tableName = this.slides[value].slide_title
            this.tableDescription = this.slides[value].dynamic_report
            this.select = this.slides[value].pivot_params_json.events
            this.columns =  this.slides[value].pivot_params_json.headers
            this.filterCols = this.slides[value].pivot_params_json.filterColumn
            this.rendererName = this.slides[value].pivot_params_json.renderer
            this.aggregatorName = this.slides[value].pivot_params_json.aggregator
            this.cols = this.slides[value].pivot_params_json.cols
            this.rows = this.slides[value].pivot_params_json.rows
            this.option = this.slides[value].pivot_params_json.option
            this.yearReference = this.slides[this.page].pivot_params_json.selectedYear
            this.operation =  this.slides[value].pivot_params_json.operation
            this.showTotalRow = this.slides[this.page].pivot_params_json.showTotalRow
            this.showTotalCol = this.slides[this.page].pivot_params_json.showTotalCol
            this.$root.$emit('setValueFilter', this.slides[value].pivot_params_json.filters)
            
            this.afterOpen ? this.getData() : false
        },
    },
}
</script>

<style src="@/../public/styles/pivottable/pivottable.full.css"></style>
<style>
.svg-container {
    margin: 0 auto !important;
}
</style>